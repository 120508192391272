@font-face {
    font-family: "icon";
    src: url("fonts/icon.ttf?nqv4my") format("truetype"), url("fonts/icon.woff?nqv4my") format("woff"),
        url("fonts/icon.svg?nqv4my#icon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-moon:before {
    content: "\e902";
}
.icon-sun:before {
    content: "\e9d4";
}
.icon-github:before {
    content: "\eab0";
}

.icon-2x {
    font-size: 2em;
}
