html {
    background: #fff;
}
html.darkMode {
    filter: invert(1);
}
html.darkMode [data-do-not-invert] {
    filter: invert(1);
}

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
    margin: 0;
}

#root {
    padding: 10px;
}
